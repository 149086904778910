.footer {
  display: flex;
  justify-content: center;
  align: center;
  margin: .3em;
  flex-direction: column;
}

.footer .links {
  flex: 0;
  display: flex;
  justify-content: center;
  font-size: .9em;
}

.footer .links a {
  color: #bbb;
  text-decoration: none;
}

.footer .links a:hover {
  color: #ddd;
  text-decoration: underline;
}
