.privacy {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
}

.privacy .content {
  flex: 1;
  max-width: 80em;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  padding: 0 1em;
}

.privacy h1 {
  font-family: 'Arial';
  font-size: 2.2em;
  font-weight: normal;
  margin: 0;
  text-align: left;
}

.privacy .section {
  margin-bottom: 1.5rem;
}

.privacy .section-text {
  font-weight: 290;
}

.privacy h2 {
  font-family: 'Arial';
  letter-spacing: .3em;
  font-size: 1.4em;
  text-transform: uppercase;
  font-weight: normal;
}
