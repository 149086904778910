.landing {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.landing .content {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.landing .upper {
  display: table;
  text-align: center;
  height: 30vh;
}

.landing .upper .middle {
  display: table-cell;
  vertical-align: middle;
}

.landing .upper h1 {
  font-size: 1.5em;
  color: #fff;
  margin-top: 0;
}
@media screen and (min-width: 1200px) {
  .landing .upper h1 {
    font-size: 3.5em;
  }
}

.landing .dropArea {
  flex: .5;
  padding: 0;
}

.landing .featured  {
  flex: 1;
  background: #e58e62;
  padding: 1em;
}

.landing .carousel {
  height: 100%;
}

.landing .react-multi-carousel-track {
  height: 100%;
}

.landing .slide {
  width: 20em;
  height: 20em;
  background: white;
  border-radius: 1em;
  box-shadow: 1px 5px 5px 2px #00000022;
}

.landing .slide:hover {
  box-shadow: 1px 5px 5px 6px #00000033;
  transition: box-shadow 0.1s ease-in-out;
}

.landing .slide:hover::after {
  opacity: 1;
}
