.profile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
}

.profile .content {
  flex: 1;
  max-width: 80em;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  padding: 0 1em;
}

.profile h1 {
  font-family: 'Arial';
  font-size: 2.2em;
  font-weight: normal;
  margin: 0;
  text-align: left;
}

.profile .section {
  margin-bottom: 1.5rem;
}

.profile .section-text {
  font-weight: 290;
}

.profile h2 {
  font-family: 'Arial';
  letter-spacing: .3em;
  font-size: 1.4em;
  text-transform: uppercase;
  font-weight: normal;
}

.profile ul {
  padding: 0;
  margin: .5em 0;
  list-style: none;
}

.profile label {
  display: inline-block;
  width: 10em;
}
