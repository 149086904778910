.socialbuttonbar {
  display: flex;
  justify-content: center;
}

.social-icon {
  width: 3em;
  height: 3em;
  margin: 1em;
  opacity: .3;
  background-position: bottom;
  background-repeat: no-repeat;
}

.social-icon:hover {
  opacity: .5;
  cursor: pointer;
}

.social-icon#facebook {
}

.social-icon#github {
}

.social-icon#instagram {
}

.social-icon#pinterest {
}

.social-icon#twitter {
}

.social-icon#whatsapp {
}

.social-icon#youtube {
}
