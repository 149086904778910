@font-face {
  font-family: 'Abecedario';
  src: url('./Abecedario.woff2') format('woff2'),
       url('./Abecedario.woff') format('woff'),
       url('./Abecedario.ttf') format('truetype');
}

.configurator {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
}

.configurator .content {
  flex: 1;
  max-width: 80em;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  padding: 0 1em;
}

.configurator .title {
  font-weight: 380;
}

.configurator h1 {
  font-family: 'Abecedario';
  font-size: 4em;
  font-weight: normal;
  margin: 0;
  text-align: left;
}

.configurator .carousel {
  max-width: 95vw;
}
.configurator .react-multi-carousel-track {
  height: 35vh;
}

.configurator .slide {
  height: 100%;
}

.configurator .section {
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: row;
}

.configurator .section-text {
  flex: 1;
  font-weight: 290;
}

.configurator .section-image img {
  margin: 2em;
  margin-left: 3em;
  width: 12vw;
}

.configurator h2 {
  font-family: 'Arial';
  letter-spacing: .3em;
  font-size: 1.4em;
  text-transform: uppercase;
  font-weight: normal;
}
