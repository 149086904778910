header {
  height: 5em;
  display: flex;
}

.header-left {
  flex: .5;
}

#logo {
  position: absolute;
  top: 1em;
  left: 1em;
  width: 63px;
  height: 63px;
  opacity: .15;
}

.header-right {
  flex: .5;
  padding-right: 1.2em;
  vertical-align: middle;
  align-items: center;
  justify-content: space-between;
  display: flex;
  text-align: right;
}

.header-right span {
  flex: 1;
  text-align: right;
  color: #ccc;
  justify-content: space-between;
}

.header-right button {
  margin-left: 10px;
}

.welcome {
  color: #333;
  font-size: 14px;
  margin-right: 10px;
}

.welcome a {
  color: inherit;
}
